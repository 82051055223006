.login-background {
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: center; /* 수평 중앙 정렬 */
}

.login-container {
  background-color: #ffffff; /* 흰색 배경 */
  padding: 70px; /* 안쪽 여백 */
  border-radius: 20px; /* 모서리 둥글게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 약간의 그림자 효과 */
  width: 30%; /* 원하는 너비 */
  position: absolute; /* 절대 위치 설정 */
  top: 50%; /* 화면의 50% 위치 */
  left: 50%; /* 화면의 50% 위치 */
  transform: translate(-50%, -50%); /* 정확한 중앙으로 조정 */
}


.login-title {
  font-family: 'Pretendard', sans-serif;
  font-size: 36px; /* 제목 크기 */
  font-weight: 700; /* 제목 두께 * /* 제목 두께 */
  margin-bottom: 5px; /* 제목과 아래 요소 간격 */
  text-align: left; /* 제목 중앙 정렬 */
}

.login-message {
  font-family: 'Pretendard', sans-serif;
  font-weight: 200;
  font-size: 17px;
  font-weight: 300;
  text-align: left; /* 메시지 중앙 정렬 */
  margin-bottom: 40px; /* 메시지와 다음 요소 간격 */
}

.login-inputs{
  margin-bottom: 16px; /* 인풋 박스 간격 */
}

.login-input {
  font-family: 'Pretendard', sans-serif;
  font-weight: 300;
  font-size: 16px;
  width: 100%; /* 인풋 박스 너비를 80%로 설정 */
  padding: 14px; /* 인풋 박스 안쪽 여백 */
  background-color: #efefef;
  border-color: transparent;
  border-radius: 8px; /* 모서리 둥글게 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 너비 설정 */
  display: block; /* 블록 요소로 설정하여 여백을 올바르게 적용 */
  margin-left: auto; /* 좌측 여백 자동으로 설정 */
  margin-right: auto; /* 우측 여백 자동으로 설정 */
  text-align: left; /* 인풋 내용 좌측 정렬 */
}

.styled-error {
  font-family: 'Pretendard', sans-serif;
  font-weight: 300;
  color: #e11700; /* 에러 메시지 색상 */
  font-size: 14px; /* 에러 메시지 크기 */
}

.login-button-container {
  text-align: center; /* 버튼 중앙 정렬 */
  margin-bottom: 40px; /* 버튼과 다음 요소 간격 */
  margin-top: 16px;
}

.login-button {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  background-color: #000000; /* 버튼 배경색 */
  color: white; /* 버튼 글자색 */
  border: none; /* 버튼 테두리 없앰 */
  padding: 16px;
  width: 100%;
  border-radius: 8px; /* 버튼 모서리 둥글게 */
  cursor: pointer; /* 커서 변경 */
  font-size: 16px; /* 버튼 글자 크기 */
}

.signup-prompt {
  text-align: center; /* 메시지 중앙 정렬 */
}

.signup-go {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  font-size: 16px; /* 메시지 크기 */
  text-align: left; /* 메시지 중앙 정렬 */
}

.signup-link {
  font-family: 'Pretendard', sans-serif;
  color: #3498db; /* 링크 색상 */
  text-decoration: none; /* 링크 밑줄 제거 */
}

.signup-link:hover {
  text-decoration: underline; /* 호버 시 밑줄 */
}