
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

/* .notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
} */

.filtersection-container{
    overflow: hidden;
    height: 90vh;
    font-family: 'Noto Sans KR', sans-serif;
}

.section-container-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  height: 100%; /* 혹은 다른 값을 설정하여 레이아웃을 조정 */
  background-image: url('/public/filter/filtermain.jpg');
  background-size: 100%; 
}

.section-box {
  padding: 15px;
  border: none; 
  border-radius: 5px;
  width: 900px;
  height: 500px;
  background-color: rgba(35, 35, 35, 0.577);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.57);
  text-align: center; /* checkbox-label 가운데 정렬을 위한 추가 */
}


.section-button {
  padding: 10px 50px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffffd3;
  color: #252525;
  text-align: center;
  margin-top: 20px;
}

.section1-button:hover {
  background-color: rgba(197, 197, 197, 0.577);
  font-family: 'Noto Sans KR', sans-serif;
}


.section-text1 {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color : #e5e5e5;
  margin-top : 50px;
  margin-bottom: 10px;
  margin-left: 30px;
}

.section-text2 {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 35px;
  margin-bottom: 15px;
  font-weight: 600;
  text-align: left;
  color : #e5e5e5;
  margin-left: 15px;
}

.rightrow {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 30px;
  font-weight: 400;
  text-align: left;
  color : #e5e5e5;
  margin-left: 15px;
  margin-bottom: -2px;
}


.checkbox-label {
   display: inline-block;
   padding: 9px 30px;
   margin: 12px 10px;
   border: 2px solid #b2b2b2;
   border-radius: 10px;
   cursor: pointer;
   font-family: 'Noto Sans KR', sans-serif;
   font-size: 30px;
   color :#d8d7d7;
  }
  
  .checkbox-label:hover {
    background-color: #e7e7e77c;
  }

  .checkbox-label.selected {
    background-color: #e7e7e7c8;
    font-family: 'Noto Sans KR', sans-serif;
    color: #2c2c2c;
    font-weight: 500;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255);
    width: 300px;
    padding: 13px; /* 내부 여백 */
    border-radius: 8px; /* 둥근 모서리 */
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: rgb(24, 24, 24); /* 텍스트 색상 */
    text-align: center; /* 내용 중앙 정렬 */
  }
  
  .popup button {
    background-color: #2c2c2c; /* 닫기 버튼 배경색 */
    color: white; /* 닫기 버튼 텍스트 색상 */
    border: none; /* 테두리 없음 */
    padding: 8px 20px; /* 내부 여백 */
    border-radius: 5px; /* 둥근 모서리 */
    cursor: pointer; /* 마우스 포인터를 손가락으로 변경 */
    margin-top: 0px; /* 위쪽 여백 */
    margin-bottom: 10px;
  }
  
  .popup button:hover {
    background-color: #6f6f6f; /* 마우스 호버 시 버튼 배경색 변경 */
  }
  