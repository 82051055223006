/* result.css */

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* .notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
} */


.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 84vh;
  font-family: 'Noto Sans KR', sans-serif;
}

.result-container-box {
  margin-top: 100px;
  height: 100%;
  width: 1200px;
  max-width: 1200px;
}

.section-textbox {
  font-size: 1.5rem;
  letter-spacing: 2px;
  font-family: 'JalnanGothic';
  margin-top: 10px;
  display: flex;
}

.toscrap {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 600;
  text-decoration: none; /* 링크의 밑줄을 없앰 */
  color : #2c2c2c;
  font-size: 17px;
  margin-left: auto; /* 오른쪽 끝에 배치 */
}

.toscrap {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 600;
  text-decoration: none; /* 링크의 밑줄을 없앰 */
  color : #2c2c2c;
  font-size: 17px;
  margin-left: auto; /* 오른쪽 끝에 배치 */
}

.result-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.result {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  font-family: 'Noto Sans KR', sans-serif;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.result:hover {
  transform: scale(1.05); /* Scale up by 5% when hovering */
}


.result_img_div {
  height: 200px;
  overflow: hidden;
  display: flex; /* Flexbox 사용 */
  align-items: center; /* 수직 가운데 정렬 */
  justify-content: center; /* 수평 가운데 정렬 */
  border-bottom: 1px solid #ccc;
}

.result_img {
  display: block; /* 이미지를 블록 요소로 설정 */
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto; /* 수평 가운데 정렬 */
  cursor: pointer;
}

.result_img2 {
  width: 30%;
  height: auto;
  color: #ccc;
  cursor: pointer;
}


.result-t {
  align-items: center;
  height: 70px;
}

.result_title,
.result_title2 {
  text-align: center;
}

.result_title {
  font-size: 1.1rem;
  font-weight: 500;
  color:#2c2c2c;
}

.result_title2 {
  font-size: 14px;
  color: #757575;
  padding: 0 10px;
}

.scrapbutton {
  background: transparent;
  border: none;
  color: #2c2c2c;  
  cursor: pointer;
  display: flex;
  align-items: center; /* 추가: scrapbutton을 수직 가운데로 정렬 */
  margin-left: auto; /* 추가: scrapbutton을 오른쪽으로 이동 */
  padding: 10px;
  font-size: 30px;
}


.pagination1 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.pagination1 button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: #2c2c2c;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 20px;
}

.pagination1 button:hover {
  background-color: #afafaf;
}

.pagination1 button.active {
  background-color: #afafaf;
  color: white;
}

.popup-result {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color:white;
  border: 1px solid #b5b5b5;
  padding: 10px;
  border-radius: 5px;
}

.popup-inner1,
.popup-inner2 {
  background-color: transparent;
  padding: 20px;
  text-align: center;
}

.popup-inner1 p,
.popup-inner2 p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.popup-inner1 button,
.popup-inner2 button {
  padding: 5px 15px;
  background-color: #2f2f2f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.popup-inner1 button:hover,
.popup-inner2 button:hover {
  background-color: #5b5b5b;
}


/* Explanation Popup */
.Ex-popup-result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 9999; /* Ensure it appears above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Ex-popup-inner1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Vertical centering */
  text-align: center; /* Center align internal elements */
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 45%;
}

.Ex-popup-inner1 .centered-text {
  margin-bottom: 10px;
  font-family: 'Noto Sans KR', sans-serif;
  text-align: center; /* Center align text */
  width: 100%; /* Ensure full width to center align properly */
  font-size: 25px;
  font-weight: 700;
}

.Ex-popup-inner1 .ex-text{
  margin-bottom: 10px;
  font-family: 'Noto Sans KR', sans-serif;
  text-align: left; /* Left align text */
  width: 100%; /* Ensure full width to left align properly */
}

.Ex-popup-inner1 button {
  padding: 7px 20px;
  background-color: #2d2d2d;
  margin-bottom: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Noto Sans KR', sans-serif;
  transition: background-color 0.3s ease;
}

.Ex-popup-inner1 button:hover {
  background-color: #5a5a5a;
}

.ex-containter {
  padding-left:10px;
  padding-right: 10px;
  padding-bottom: 15px;
}