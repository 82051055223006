@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* .notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
} */


.filtermain-container{
  overflow: hidden;
  height: 90vh;
  font-family: 'Noto Sans KR', sans-serif;
}

.container-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* 혹은 다른 값을 설정하여 레이아웃을 조정 */
  background-image: url('/public/filter/filtermain.jpg');
  background-size: 100%; 
  font-family: 'Noto Sans KR', sans-serif;
}

/* 텍스트 묶음 */
.filter-text {
  text-align: center;
  font-family: 'Noto Sans KR', sans-serif;
  margin-bottom: 100px;
}

.text-user {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
  color : #ffffff;
  background-color: #ffffff3b;
  border-radius: 30px;
  padding: 0px 0px 3px;
  width: 500px;
}


/* 여행지추천 */
.text-main {
  font-family: 'JalnanGothic';
  font-size: 65px;

  color: #ffffff;
  letter-spacing: 7px;
  margin-bottom: 20px;
  
}

.text-ex {
  font-size: 17px;
  color : #e5e5e5;
}

@keyframes moveIcon {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}

.button-start {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #2c2c2c;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  padding: 15px 25px;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.577);
  transition: all 0.3s ease;
}

.button-start:hover {
  background-color: #444444;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.577);
}

.start-icon {
  margin-right: 10px;
  font-size: 24px;
  animation: moveIcon 1s infinite alternate;
}
