@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.container {
  max-width: 75%;
  margin: 0 auto;
  padding: 20px;
  margin-top: 20px;
  font-family: 'Noto Sans KR', sans-serif;
  height: auto; /* container의 높이를 자동으로 조절 */  
}

.matepost-details {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 30px;
  height: auto; /* 내용이 컨테이너를 벗어날 경우에도 전체 높이를 유지합니다 */
  overflow: auto; /* 내용이 trippost-details를 벗어날 경우 스크롤바 표시 */
  font-family: 'Noto Sans KR', sans-serif;
}

.matepost-title {
  font-size: 30px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left:5px;
  font-family: 'Noto Sans KR', sans-serif;
}

.matepost-info {
  margin-bottom: 20px;
  margin-left : 10px;
  font-family: 'Noto Sans KR', sans-serif;
}

.content-box {
  border: 1px solid #cecece;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  padding : 5px 10px;
  overflow-y: auto; /* 내용이 상자를 벗어날 경우 스크롤바를 표시합니다 */
  font-family: 'Noto Sans KR', sans-serif;
}


.pre-wrap {
  white-space: pre-wrap;
}

.matepost-content {
  font-size: 15px;
  height: 300px;
  font-family: 'Noto Sans KR', sans-serif;
}

.action-button {
  padding : 7px;
  width : 80px;
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Noto Sans KR', sans-serif;
  margin : 5px;
}

.action-buttons {
  margin-top: 15px;
  display: flex;
  gap: 5px; /* 버튼 사이의 간격 */
}

.action-buttons button {
  padding : 7px;
  width : 100px;
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Noto Sans KR', sans-serif;
  margin: 5px;
}

.action-buttons button:hover {
  background-color: #757575; /* 마우스 호버 시 배경색 변경 */
}

.edit-form {
  background-color: #f9f9f9;
}

.edit-form input {
  flex: 85%; /* 입력 필드의 너비를 설정 */
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: 'Noto Sans KR', sans-serif;
  resize:none;
}

.edit-form textarea {
  flex: 85%; /* 입력 필드의 너비를 설정 */
  height: 300px; /* 내용 입력란에 대한 높이 설정 */
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: 'Noto Sans KR', sans-serif;
  resize:none;
}

.error {
  color: red;
}

.not-found {
  color: gray;
}

.comment-header {
  font-size: 23px;
  color: #2c2c2c;
  font-weight: 600;
  margin: 30px 0px 10px 5px;
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 2px;
}

.comment-section {
  margin-top: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: block;
}

.comment-section form {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-family: 'Noto Sans KR', sans-serif;
}

.comment-section textarea {
  flex: 1; /* 수정된 부분: textarea가 남은 공간을 모두 채우도록 함 */
  padding: 10px;
  margin: 5px;
  letter-spacing: 1px;
  border-radius: 10px;
  height: 70px;
  resize: none;
  font-family: 'Noto Sans KR', sans-serif;
}

.comment-section button {
  padding: 10px 20px;
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Noto Sans KR', sans-serif;
  margin : 5px;
}

.comment-semiheader {
  font-weight: bold;
  font-size: 18px;
  color :#2c2c2c;
  margin-left : 15px;
}

.comment-list {
  margin-top: 30px;
  font-family: 'Noto Sans KR', sans-serif;
}

.comment-container {
  border-bottom: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 5px; /* 오른쪽 여백을 5px로 지정 */
}

.depth-1 .comment-container {
  border-bottom : none;
  margin-left: 20px;
}

.comment-box {
  padding: 5px;
  border-radius: 10px;
}

.comment-container .info {
  font-family: 'Noto Sans KR', sans-serif;
  color: #757575;
  font-size: 14px;
  margin-left: 5px;
  display: flex; /* 요소들을 플렉스 박스로 설정합니다. */
  justify-content: space-between; /* 요소들을 좌우로 최대한으로 분배하여 정렬합니다. */
  align-items: center; /* 요소들을 수직 가운데 정렬합니다. */
}

.comment-container .info button {
  padding: 5px 15px; /* 버튼 내부 여백 설정 */
  background-color: #383838; /* 버튼 배경색 설정 */
  color: white; /* 버튼 텍스트 색상 설정 */
  border: none; /* 버튼 테두리 없애기 */
  border-radius: 10px; /* 버튼 모서리 둥글게 설정 */
  cursor: pointer; /* 마우스 커서 모양 변경 */
}

.comment-container .info button:hover {
  background-color: rgb(100, 100, 100); /* 마우스를 올렸을 때 버튼 배경색 변경 */
}


.comment-container:last-child {
  border-bottom: none;
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-modal-content {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.delete-modal-content button {
  font-family: 'Noto Sans KR', sans-serif;
  padding: 4px 25px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background-color: #2c2c2c;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}
