@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.moreplace-container {
  font-family: 'Noto Sans KR', sans-serif;
    height: 89vh; /* 뷰포트 높이에 맞춤 */
    display: flex;
    overflow: hidden; /* 세로 스크롤 제거 */
}

.place-boxes {
    display: flex;
    height: 100%; /* 부모의 높이에 맞춤 */
    width: 100%; /* 전체 화면 너비에 맞춤 */
}

.place1-box, .place2-box {
    border: 1px solid #ddd;
    position: relative;
    height: 100%; /* 부모의 높이에 맞춤 */
}
.place1-box {
    border: 1px solid #ddd;
    flex: 3.5; /*가로길이 조절*/
    position: relative;
    height: 100%; /* 전체 화면 높이에 맞춤 */
    margin-left: 10px;
}
  
.place2-box {
  position: relative; /* 부모 요소를 relative로 설정 */
  flex: 1;
  height: 100%; /* 전체 화면 높이에 맞춤 */
  display: flex;
  flex-direction: column;
}

.map {
    width: 100%;
    height: 100%; /* You can adjust the height as needed */
    border-radius: 5px;
    z-index: 1;
}

  /*스크랩*/
  .more-scrap-container {
    flex: 1;
    overflow-y: auto; /* 리스트가 길어지면 스크롤 가능 */
    padding: 20px;
    border-left: 2px solid #ccc;
  }
  
  .toggle-recommendation-button {
    position: absolute; /* 절대 위치로 고정 */
    bottom: 20px; /* 하단에서 20px 위에 배치 */
    left: 20px; /* 좌측에서 20px 오른쪽에 배치 */
    width: calc(100% - 40px); /* 양쪽 간격을 고려한 버튼 너비 */
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }

.more-scrap-list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: hidden; /* 리스트 내부에는 스크롤을 숨김 */
    margin-bottom: 40px; /* 하단에 공간 추가 */

}


    
  .more-scrap-buttons button {
    font-family: 'Noto Sans KR', sans-serif;
    letter-spacing: 1px;
    padding: 13px 30px; /* 버튼 내부 여백 설정 */
    background-color: #3f3f3f; /* 배경색 설정 */
    color: white; /* 글자색 설정 */
    border: none; /* 테두리 없앰 */
    border-radius: 10px; /* 버튼 모서리를 둥글게 만듦 */
    cursor: pointer; /* 커서를 포인터로 변경하여 마우스 호버 시 인터랙션을 나타냄 */
    transition: background-color 0.2s ease; /* 배경색 변화에 대한 부드러운 전환 효과 추가 */
    margin-right: 7px; /* 각 버튼 사이의 간격을 조정합니다. */
  }

  /* 버튼에 호버 효과 추가 */
  .more-scrap-buttons button:hover {
    background-color: #5a5a5a; /* 호버 시 배경색 변경 */
  }


  .more-scrap-title {
    font-size: 25px;
    font-weight: 700;
    font-family: 'Noto Sans KR', sans-serif;
    margin-bottom: 25px;
  }

  .more-scrap-list {
    list-style: none;
    padding: 0;
  }

  .more-scrap-list li {
    position: relative; /* 상대 위치로 설정하여 자식 요소에 대한 위치 조정 가능 */
    padding: 10px;
    margin-bottom: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #a7a7a7;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .more-scrap-list li:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    background-color: rgb(243, 243, 243);
  }

  .more-scrap-list li.selected {
    border: 2px solid #387eff;
  }
    
  
  .more-scrap-list li strong {
    margin : 0px 5px;
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    color: #333;
  }
  
  .more-scrap-list li p {
    margin: 3px 5px 0;
    font-size: 0.9em;
    color: #666;
  }

  .more-delete-button {
    background-color: transparent;
    border-radius: 5px;
    padding : 5px 5px;
    color: #2c2c2c;
    border: none; /* 테두리 없음 */
    cursor: pointer; /* 마우스 오버 시 커서 모양 */
    font-size: 14px; /* 폰트 크기 */
    position: absolute; /* 절대 위치로 설정하여 부모 요소에 대한 위치 설정 */
    top: 5px; /* 상단 여백 조정 */
    right: 5px; /* 우측 여백 조정 */
    width: 20px;
    height: 20px;
  }

  .more-delete-button:hover {
    background-color: rgb(200, 200, 200);
  }

  /*팝업*/
  .more-popup-result {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:white;
    border: 1px solid #b5b5b5;
    padding: 10px;
    border-radius: 5px;
    z-index: 10000; /* z-index를 높게 설정 */
  }

  .more-popup-inner1,
.more-popup-inner2,
.more-popup-inner3 {
  background-color: transparent;
  padding: 20px;
  text-align: center;
}

.more-popup-inner1 p,
.more-popup-inner2 p,
.more-popup-inner3 p {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.more-popup-inner1 button,
.more-popup-inner2 button,
.more-popup-inner3 button {
  padding: 5px 15px;
  background-color: #2f2f2f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.more-popup-inner1 button:hover,
.more-popup-inner2 button:hover,
.more-popup-inner3 button:hover {
  background-color: #5b5b5b;
}



/*새로 추가한 부분 */
.mini-recommendation-window {
  position: fixed;
   bottom: 5px; /* 화면의 아래쪽에서 20px 위에 배치 */
  left: 20px;   /* 화면의 왼쪽에서 20px 떨어진 곳에 배치 */
  max-height: 500px; /* 창의 최대 높이를 지정 */
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 10px;
}

.mini-recommendation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* 부모 요소를 상대적으로 설정 */
}

.mini-recommendation-header h3 {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 23px;
  margin-left: 15px;
}

.mini-recommendation-list {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 리스트 사이 간격 */
  padding: 10px;
  max-height: 400px; /* 최대 높이 설정 (필요에 따라 조정) */
  overflow-y: auto; /* 세로 스크롤 활성화 */
}

.recommendation-item {
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #a7a7a7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: box-shadow 0.3s ease;
  cursor: pointer; /* 클릭 가능한 UI임을 표시 */
}

.recommendation-item:hover {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  background-color: rgb(243, 243, 243);
}

/* 선택된 항목의 border 색상을 파란색으로 변경 */
.recommendation-item.selected {
  border: 2px solid #387eff;
}

.recommendation-item strong {
  display: block;
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.recommendation-item p {
  margin: 3px 0;
  font-size: 0.9em;
  color: #666;
}


.recommendation-button-container {
  position: relative; /* 고정 위치에서 상대 위치로 변경 */
  z-index: 1; /* 다른 요소 위에 올라가도록 설정합니다. */
  background: #f9f9f9; /* 배경색 추가로 컨테이너와 구분 */
  justify-content: center; /* 버튼을 중앙으로 정렬합니다. */
}

.toggle-recommendation-button {
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 1px;
  padding: 13px 32px; /* 버튼 내부 여백 설정 */
  background-color: #3f3f3f; /* 배경색 설정 */
  color: white; /* 글자색 설정 */
  border: none; /* 테두리 없앰 */
  border-radius: 10px; /* 버튼 모서리를 둥글게 만듦 */
  cursor: pointer; /* 커서를 포인터로 변경하여 마우스 호버 시 인터랙션을 나타냄 */
  transition: background-color 0.2s ease; /* 배경색 변화에 대한 부드러운 전환 효과 추가 */
  margin-right: 10px; /* 각 버튼 사이의 간격을 조정합니다. */
}

.close-button {
  position: absolute; /* X 버튼을 절대 위치로 설정 */
  top: 10px; /* 상단에서 10px 떨어짐 */
  right: 10px; /* 오른쪽에서 10px 떨어짐 */
  background: none; /* 배경 없애기 */
  border: none; /* 테두리 없애기 */
  cursor: pointer; /* 마우스를 올렸을 때 커서 변경 */
  font-size: 1.5rem; /* X 아이콘 크기 조절 */
}

/*장소 선택해주세용~*/
.more-selectpopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 배경 투명도 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.more-selectpopup {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.more-selectpopup button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #3f3f3f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.more-selectpopup button:hover {
  background-color: #5a5a5a;;
}


