@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);


.form-container {
  max-width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px;
  border-radius: 15px;
  background-color: #f2f2f2;
  font-family: 'Noto Sans KR', sans-serif;
}

.form-group {
  margin-bottom: 15px;
  display: flex; /* 부모 요소를 플렉스 박스로 설정 */
}

.label {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 3px;
  display: block;
  flex: 0 0 10%; /* 라벨의 너비를 설정 */
  font-family: 'Noto Sans KR', sans-serif;
}

.input-field {
  flex: 85%; /* 입력 필드의 너비를 설정 */
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: 'Noto Sans KR', sans-serif;
  resize:none;
}

.input-field[name="content"] {
  height: 450px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap:30px;
  text-align: center;
}

.button {
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #2c2c2c;
  letter-spacing: 3px;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 8px 20px;
  text-align: center;
  font-size: 16px;
  width : 7%; 
  cursor: pointer;
}

.button:hover {
  background-color: #595959;
}

.modal {
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경을 어둡게 */
}

.modal-content2 {
  background-color: white;
  padding: 30px; /* 내부 패딩을 조절하여 모달 크기 조정 */
  margin: 0 auto;
  border-radius: 10px;
  text-align: center;
  font-size: 18px;
}


.modal-button {
  justify-content: center;
  align-items: center;
  display: flex;
  gap:20px;
  border:none;
}

.modal-content2 button {
  font-family: 'Noto Sans KR', sans-serif;
  padding: 8px 33px;
  border-radius: 5px;
  letter-spacing: 3px;
  font-size: 13px;
  cursor: pointer;
  border:none;
  text-align: center;
  margin-top: 23px;
  background-color: #2c2c2c;
  color: white;
}

.modal-content2 button:hover {
  background-color: #595959;
}

.custom-error-message {
  font-family: 'Noto Sans KR', sans-serif;
  color: red; /* 오류 메시지 텍스트의 색상을 빨간색으로 설정합니다. */
  font-size: 15px; /* 오류 메시지 텍스트의 글꼴 크기를 설정합니다. */
  margin-top: 10px; /* 오류 메시지와 다른 요소 사이의 위쪽 여백을 설정합니다. */
  text-align: right;
}