.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 30px; /* 내부 패딩을 조절하여 모달 크기 조정 */
    margin: 0 auto;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 60%; /* 추가: modal-image의 최대 너비와 일치 */
    width: auto;
}

.modal-image {
    width: 100%;
    height: auto;
}