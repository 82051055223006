@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&display=swap');

/* font-family: 'Noto Sans KR', sans-serif; */

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TmonMonsori';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/TmonMonsori.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.banner-container {
  position: relative;
  width: 100vw;
  height: 88vh; /* Full screen height */
}

.Img {
  width: 100%;
  height: 88vh; /* 뷰포트 높이만큼 이미지 높이를 설정 */
  object-fit: cover; /* 이미지 비율을 유지하면서 전체를 덮도록 설정 *
  position: relative; /* 오버레이를 이 이미지 위에 배치하기 위해 설정 */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* 검정색 오버레이, 80% 불투명도 */
  pointer-events: none; /* 오버레이가 클릭 이벤트를 차단하지 않도록 설정 */
}

.main-text {
  font-family: 'Noto Sans KR', sans-serif; 
  position: absolute;
  padding: 10px;
  bottom: 20px;
  right: 60px;
  color: rgba(255, 255, 255, 0.823);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  z-index: 2; /* 이미지 위에 텍스트가 보이도록 설정 */
}

.banner-text {
  position: absolute;
  top: 51%;
  transform: translateY(-50%);
  left: 10%;
  z-index: 3; /* 이미지 위에 텍스트가 보이도록 설정 */
  color: white;
  font-size: 55px;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: 'TmonMonsori';
  /* font-family: "Kanit", sans-serif; */
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.5); /* 그림자 효과 추가 */
}

.banner-text2 {
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  left: 10.5%;
  z-index: 3; /* 이미지 위에 텍스트가 보이도록 설정 */
  color: rgba(255, 255, 255, 0.941);
  font-size: 23px;
  font-weight: 500;
  font-family: 'Noto Sans KR', sans-serif;
  letter-spacing: 2px;
}

.left-icon, .right-icon {
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
  color: white;
}

.left-icon {
  left: 10px;
}

.right-icon {
  right: 10px;
}

.func-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  top: 73%;
  right: 8%;
  transform: translateY(-50%);
  z-index: 4;
  font-family: 'Noto Sans KR', sans-serif;
}

.func1, .func2, .func3, .func4 {
  padding: 23px;
  font-size: 24px;
  color: #ffffff;
  border-radius: 8px;
  font-family: 'JalnanGothic';
  transition: transform 0.2s ease;
  position: relative;
  width: 360px;
  cursor: pointer;
}

.moveto {
  position: absolute;
  bottom: 13px;
  right: 0px;
  font-size: 38px;
  color: #ffffff;
  transition: transform 0.2s ease;
  text-decoration: none;
}


@keyframes moveArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.func1:hover .moveto,
.func2:hover .moveto,
.func3:hover .moveto,
.func4:hover .moveto {
  animation: moveArrow 0.7s linear infinite;
}


.func1:hover,
.func2:hover,
.func3:hover,
.func4:hover {
  transform: translateX(10px);
}

/* .func-container {
  display: flex;
  justify-content: space-between;
  width: 1500px;
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  font-family: 'Noto Sans KR', sans-serif;
  text-decoration: none;
} */

/* .func1, .func2, .func3, .func4 {
  background-color: #ffffff;
  width: 280px;
  height: 160px;
  padding: 25px;
  font-size: 25px;
  color: #191919;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-family: 'JalnanGothic';
  transition: transform 0.2s ease;
  position: relative;
} */

/* .moveto {
  position: absolute;
  bottom: 0px;
  right: 20px;
  font-size: 45px;
  color: #191919;
  transition: transform 0.2s ease;
  text-decoration: none;
} */

/* @keyframes moveArrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
} */

/* .moveto svg {
  transition: transform 0.2s ease;
} */

/* .func1:hover .moveto svg,
.func2:hover .moveto svg,
.func3:hover .moveto svg,
.func4:hover .moveto svg {
  animation: moveArrow 0.7s linear infinite;
} */

/* .func1:hover .moveto,
.func2:hover .moveto,
.func3:hover .moveto,
.func4:hover .moveto {
  color: #2395ff;
} */

/* .func1:hover,
.func2:hover,
.func3:hover,
.func4:hover {
  transform: translateY(-10px);
  color: #0084ff;
} */


.detail {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  font-family: 'Noto Sans KR', sans-serif;
  width: auto;
}

.footer {
  background-color: #ffffff;
  height: 60px;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
}

.footer-text {
  font-size: 12px;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 600;
  color: #8d8d8d;
  margin-left: 30px;
}

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal-content {
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-modal-content button {
  font-family: 'Pretendard', sans-serif;
  padding: 6px 28px;
  margin: 5px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.login-modal-content button:nth-of-type(1) {
  background-color: #2c2c2c; /* 로그인 버튼 배경색 */
  color: white;
}

.login-modal-content button:nth-of-type(2) {
  background-color: #dbdbdb; /* 취소 버튼 배경색 */
  color: #2c2c2c;
}

.login-modal-content button:nth-of-type(1):hover {
  background-color: #5e5e5e; /* hover 시 배경색 변경 */
  color: #ffffff; /* hover 시 글자색 변경 */
}