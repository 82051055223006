@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
/* .notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
} */

.container {
    max-width: 75%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 20px;
    font-family: 'Noto Sans KR', sans-serif;
    height: auto; /* container의 높이를 자동으로 조절 */
  }
  
  .trippost-details {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 30px;
    height: auto; /* trippost-details의 높이를 700px로 고정 */
    overflow: auto; /* 내용이 trippost-details를 벗어날 경우 스크롤바 표시 */
    font-family: 'Noto Sans KR', sans-serif;
  }
  
  .images-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 20px;
    overflow-x: auto;
  }
  
  .post-image {
    margin: 10px;
    max-width: 300px; /* 원하는 최대 너비 설정 */
    max-height: 200px; /* 원하는 최대 높이 설정 */
    width: auto; /* 이미지의 가로 크기 자동 조정 */
    height: auto; /* 이미지의 세로 크기 자동 조정 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경하여 사용자에게 클릭 가능함을 알림 */
}

.trippost-title {
  font-size: 30px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left:5px;
  font-family: 'Noto Sans KR', sans-serif;
}

.trippost-info {
  margin-bottom: 5px;
  margin-left : 10px;
  font-family: 'Noto Sans KR', sans-serif;
}

.content-box {
  border: 1px solid #cecece;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  padding : 15px 15px;
  overflow-y: auto; /* 내용이 상자를 벗어날 경우 스크롤바를 표시합니다 */
  font-family: 'Noto Sans KR', sans-serif;
}

.pre-wrap {
  white-space: pre-wrap;
}


.trippost-content {
  font-size: 15px;
  height: 300px;
  font-family: 'Noto Sans KR', sans-serif;
}

.error {
  color: red;
}

.not-found {
  color: gray;
}

.content-box {
  margin-top: 0px;
  font-family: 'Noto Sans KR', sans-serif;
}

textarea {
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-family: 'Noto Sans KR', sans-serif;
}

