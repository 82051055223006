@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

/* .notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
} */

.draggable-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
}

.custom-chatbot {
  font-family: 'Noto Sans KR', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 상자 그림자 추가 */
  border-radius: 10px; /* 모서리 둥글게 설정 */
  overflow: hidden; /* 모서리가 둥글어질 때 콘텐츠가 넘치지 않도록 설정 */
}

  /* 챗봇 메시지 스타일 */
  .custom-chatbot .rsc-ts-bubble {
    background-color: #f8f8f8; /* 배경 색상 설정 */
    color : #494949;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 500;
    border: 1px solid #dddddd;
  }

  .custom-chatbot .rsc-os-option-element {
    background-color: #f8f8f8; /* 배경 색상 설정 */
    color : #494949;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 400;
    border: 1px solid #dddddd;
    border-radius: 15px; /* 옵션 모서리를 둥글게 설정 */
  }

  .custom-chatbot .rsc-os-option-element:hover {
    background-color: #e1e1e1; /* 옵션 요소에 마우스를 올렸을 때 배경색 변화 */
    border : 1px solid #b4b4b4;
    font-weight: 500;
  }
  

  .custom-chatbot .rsc-header {
    font-family: 'Noto Sans KR', sans-serif;
    cursor: move;
    background-color: #2e2e2e;
    color: #f1f1f1;
    font-size: 18px; /* 상단 배너 글자 크기 설정 */
  }

  .message {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #494949; /* 메시지 텍스트 색상 */
    max-width: 95%; /* 메시지 최대 너비 설정 */
    word-wrap: break-word; /* 길이가 긴 단어도 줄바꿈 */
  }
  
  .message.loading {
    color: #999; /* 로딩 상태의 메시지 텍스트 색상 */
    font-style: italic; /* 로딩 상태의 메시지 글자 스타일 */
  }

  .custom-chatbot input::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-size:13px;
  }