@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.mate-board-banner {
  position: relative; /* 위치 지정 */
  text-align: center; /* 이미지와 텍스트를 가운데 정렬합니다. */
}

.mate-board-banner img {
  max-width: 100%;
  height: auto;
  display: block; /* 이미지를 블록 요소로 설정하여 줄 바꿈을 방지합니다. */
  margin: 0 auto; /* 가운데 정렬 */

}

/* 여행 게시판 */
.mate-board-main {
  position: absolute; /* 상대 위치 지정 */
  top: 50%; /* 부모 요소의 50% 지점에서 시작 */
  left: 50%; /* 부모 요소의 50% 지점에서 시작 */
  transform: translate(-50%, -50%); /* 센터 정렬 */
  font-size: 30px;
  font-weight: bold;
  color: #f0f0f0;
  letter-spacing: 3px;
  font-family: 'Noto Sans KR', sans-serif;
  z-index: 1; /* 텍스트를 이미지 위에 표시하기 위해 z-index를 추가합니다. */
}


/* 게시글 헤더 */
.post-header {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  color: #292929;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 2px;
  background-color: #e7e7e7;
  border-radius: 10px;
  font-family: 'Noto Sans KR', sans-serif;
}

.post-header-item {
  flex: 1;
  padding: 10px;
}

.post-header-item:nth-child(1) {
  margin-left:15px;
  flex: 0.5; /* 번호 칸의 크기를 줄입니다. */
}
.post-header-item:nth-child(2) {
  flex: 5; /* 번호 칸의 크기를 줄입니다. */
}
.post-header-item:nth-child(3) {
  flex: 1; /* 작성자 칸의 크기를 조정합니다. */
}

/* 게시글 작성일 */
.post-header-item:nth-child(4) {
  flex: 1; /* 작성일 칸의 크기를 조정합니다. */
  text-align: right; /* 오른쪽 정렬 */
  margin-right : 25px;
}

.post-header-item:last-child {
  border-right: none;
}

/* 게시판 컨테이너 */
.mate-board-container {
  min-height: 50vh; /* 최소 높이를 브라우저 뷰포트 높이의 100%로 설정 */
  margin: 20px auto;
  width: 75%;
  font-family: 'Noto Sans KR', sans-serif;
}

/* 게시글 목록 */
.post-list {
  display: flex;
  flex-direction: column;
}

/* 개별 게시글 아이템 */
.post-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  width: 98%;
  margin: auto;
}

/* 마지막 게시글 아이템 */
.post-item.last-item {
  border-bottom: none;
}

/* 게시글 내용 */
.post-content {
  display: flex;
  flex: 1;
  align-items: center;
  padding : 13px;
}

/* 게시글 번호 */
.post-data:nth-child(1) {
  flex: 0.5; /* 번호 칸의 크기를 줄입니다. */
}

/* 게시글 제목 */
.post-data:nth-child(2) {
  flex: 3.8;  /* 제목 칸의 크기를 늘립니다. */
}

/* 게시글 작성자 */
.post-data:nth-child(3) {
  flex: 1.3; /* 작성자 칸의 크기를 조정합니다. */
  text-align: center;
}

/* 게시글 작성일 */
.post-data:nth-child(4) {
  flex: 1; /* 작성일 칸의 크기를 조정합니다. */
  text-align: right; /* 오른쪽 정렬 */
  margin-right : 5px;
}

/* 게시글 제목 */
.post-title {
  color: #000000;
  text-decoration: none;
}

/* 게시글 제목 호버 */
.post-title:hover {
  font-weight: bold;
  text-decoration: underline;
}

/* 글쓰기 버튼 */
.write-post-button {
  display: block;
  width: 80px;
  margin: 20px 20px 20px auto; /* Add margin-left:auto to push it to the right */
  padding: 7px;
  background-color: #2c2c2c;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  font-family: 'Noto Sans KR', sans-serif;
}


/* 글쓰기 버튼 호버 */
.write-post-button:hover {
  background-color: #555555;
}
